import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "./components/Header";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import ForgetPassword from "./pages/ForgetPassword";
import NewPassword from "./pages/NewPassword";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/signup" element={<SignUp />}></Route>
          <Route path="/signin" element={<SignIn />}></Route>
          <Route path="/forget-password" element={<ForgetPassword />}></Route>
          <Route path="/new-password" element={<NewPassword />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
