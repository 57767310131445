import React from 'react'
import authImage from '../assets/auth-image.png'
import logo from '../assets/turkiz-logo.svg'
function SignIn() {
  return (

    <>


    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-6 min-100 bg-main text-white">
                <img src={authImage} className='rounded center-block img-auth'/>
            </div>
            <div class="col-6 min-100 bg-gradient pt-5">
                <div className='container w-75 pt-5'>
      

      

                <div class="container d-flex justify-content-center">
    <div class="row align-items-center">
      <div class="col-auto">
        <h2 className='text-white'>Open account with</h2>
      </div>
      <div class="col-auto">
        <img src={logo} alt="Logo" class="logo" />
      </div>
    </div>
  </div>
                <p className='text-white text-center font-light pt-2 text-xxxl'>Start your 30 days free trial</p>


                <form className='pt-3'>
                <div class="form-group pb-3 ">
    <label for="exampleInputEmail1" className='auth-label'>Full Name:</label>
    <input type="email" class="form-control auth-input" placeholder="e.g Joe Jonas" />
  </div>
  <div class="form-group pb-3">
    <label for="exampleInputEmail1" className='auth-label'>Email:</label>
    <input type="email" class="form-control auth-input" placeholder="e.g JoeJonas@gmail.com" />
  </div>
  <div class="form-group pb-3">
    <label for="exampleInputEmail1" className='auth-label'>Password:</label>
    <input type="email" class="form-control auth-input" placeholder="Password:" />
  </div>
  <div class="form-group pb-4">
    <label for="exampleInputPassword1" className='auth-label'>Verify Password:</label>
    <input type="password" class="form-control auth-input" placeholder="Verify Password:" />
  </div>
  <button type="submit" class="btn btn-primary text-dark get-started mb-3">Get Started</button>
</form>
<div class='hr'>
    <span class='hr-title'>or</span>
</div>

<button type="submit" class="btn mt-3 g-auth text-white">Sign Up with Google</button>
<span className='text-center p-3'>
<p ><a href="login.html" className='text-gray'>I already have an account</a></p>
</span>
            </div>
            </div>
        </div>
    </div>

</>

  )
}

export default SignIn
