import React from 'react';
import authImage from '../assets/auth-image.png';
import logo from '../assets/turkiz-logo.svg';

function ForgetPassword() { 
  
  return ( 
  
  <>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-6 min-100 bg-main text-white">
        <img src={authImage} className='rounded center-block img-auth' />
      </div>
      <div class="col-6 min-100 bg-gradient pt-5">
        <div className='container w-75 pt-5 login'>
          <div class="container d-flex justify-content-center">
            <div class="row align-items-center">
              <div class="col-auto">
                <h2 className='text-white'>Forgot your password?</h2>
              </div>
            </div>
          </div>
          <p className='text-white text-center font-light pt-2 text-xxxl'>Fill in your details and check your email.</p>
          <form className='pt-3'>
            <div class="form-group pb-3">
              <label for="exampleInputEmail1" className='auth-label'>Email:</label>
              <input type="email" class="form-control auth-input" placeholder="e.g JoeJonas@gmail.com" />
            </div>
            <button type="submit" class="btn btn-primary text-dark get-started mb-3">Send</button>
          </form>   
        </div>
        <div class="logo">
            <img src={logo} alt="Logo" class="logo" />
        </div>
      </div>
    </div>
  </div>
</> 

)} 

export default ForgetPassword